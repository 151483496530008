<script>
import useVuelidate from '@vuelidate/core'
import {required} from '@vuelidate/validators'
import {FilterMatchMode} from 'primevue/api';

/* COMPOSANTS */
import AkFormList from "@components/layout/AkFormList";
import AkInputText from "@components/input/AkInputText";
import AkDialog from "@components/general/AkDialog";

/* MIXINS */
import randomRef from "@mixins/randomRef";
import utilsMixin from "@mixins/utilsMixin";
import roleMixin from "@mixins/roleMixin";

/* SERVICES */
import ceiService from "@services/ceiService";
import userService from "@services/userService";

export default {
  components: {AkFormList, AkInputText, AkDialog},
  mixins: [randomRef, utilsMixin, roleMixin],
  metaInfo() {
    return {
      title: "user.list"
    }
  },
  setup() {
    return {v$: useVuelidate()}
  },
  data() {
    return {
      loading: false,
      list: [],
      current: {},
      
      ceiList: [],
          
      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
      },
      currentCei : null
    }
  },
  validations() {
    return {
      current: {
        lastName: {required},
        firstName: {required},
        // password: {required: requiredIf(!this.current.id && this.isPasswordRequired)},
        // email: {required:requiredIf(this.isLoginRequired), email},
        // userGroupId: {required}
      }
    }
  },
  mounted() {
    this.getRef().showTotalLoader();
  
    // let p0 = rolePermissionService.authorizeAccessByPermission(this.permissionConst.admin.view);

  
    let p3 = ceiService.findAllCurrent();
    p3.then(data => {
      this.ceiList = data;
      this.currentCei = this.ceiList[0];
      this.list = [];
      ceiService.getCeiAgents(this.ceiList[0].id).then(data => {
        this.list.push(...data);
      });
    } );


    


    Promise.all([p3]).then(()=>{
      this.loading = false;
      this.getRef().hideLoader();
    });
  },
  methods: {
    exportList() {
     userService.exportList();
    },
    create() {
      this.getRef().resetMessages();
      this.v$.$touch();
      console.log(this.v$.$errors)
      if (this.v$.$error) return;
      
      this.getRef().showTotalLoader();
      
      
      ceiService.createAgent(this.currentCei.id, this.current).then(data => {
        this.list.push(data);
        this.current = {};
        
        this.getRef().success(this.$t("user.added"));
        this.$refs.dialogCreate.hide();
      }).catch(e => {
        if (e.response) {
          if (e.response.status === 409) this.getRef().error(this.$t('login_already_exists'));
        } else {
          this.getRef().error(this.$t('unknown_error'));
        }
      }).finally(() => {
        this.getRef().hideLoader();
      });
    },
    update() {
      this.getRef().resetMessages();
      this.v$.$touch();
      if (this.v$.$error) return;
      
      this.getRef().showTotalLoader();
      
      ceiService.updateAgent(this.currentCei.id, this.current).then(data => {
        this.replaceInListById(this.list, data);
        this.current = {};
        
        this.getRef().success(this.$t("user.updated"));
        this.$refs.dialogUpdate.hide();
      }).catch(e => {
        if (e.response) {
          if (e.response.status === 409) this.getRef().error(this.$t('login_already_exists'));
        } else {
          this.getRef().error(this.$t('unknown_error'));
        }
      }).finally(() => {
        this.getRef().hideLoader();
      });
    },
    delete() {
      this.getRef().resetMessages();
      ceiService.deleteAgent(this.currentCei.id,this.current.id).then(data => {
        this.removeInListById(this.list, data.id);
        this.getRef().success(this.$t("user.deleted"))
      }).catch(e => {
        this.getRef().error(e.response.data.error);
      });
    },
    getUserGroupById(id) {
      return this.ugList.filter(ug => ug.id === id)[0];
    },
    openCreateDialog() {
      this.current = {};
      this.$refs.dialogCreate.show();
    },
    openUpdateDialog(data) {
      this.current = {...data};
      this.$refs.dialogUpdate.show();
    },
    openDeleteDialog(data) {
      this.current = data;
      this.$refs.dialogDelete.show();
    },
  },
  computed: {
    
  },
  watch: {
    ceiListForCurrent() {
      if(!this.ceiListForCurrent.some(cei => cei.id===this.current.ceiId))
        this.current.ceiId = undefined;
    }
  }
}
</script>

<template v-if="!roleLoading">
  <AkFormList :ref="ref" :title="$t('ceiAgent.list')">
    <template v-slot:action>
      <span class="p-input-icon-left search-bar-desktop" style="margin-right: 10px">
        <i class="pi pi-search" />
        <InputText v-model="filters['global'].value" :placeholder="$t('search_here')" />
      </span>
      <button v-if="this.canManageCeiUserAdmin()"
        class="btn btn-inverse-primary float-right" 
        @click="openCreateDialog()">
        <i class="fe fe-plus pr-1"/>
        {{ $t('add') }}
      </button>
      <!-- <button
         class="btn btn-inverse-primary mr-1" 
         @click="exportList">
         <i class="fe fe-download pr-1"/>
         {{ $t('user.exportList') }}
      </button> -->
    </template>
    <template v-slot:search>
      <div class="row search-bar-mobile">
        <div class="col-lg-12">
          <div class="card card-statistics mb-30">
            <div class="card-body">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" :placeholder="$t('search_here')" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:list>
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="table-responsive">
                <DataTable
                  :always-show-paginator="false" :loading="loading" :paginator="true" :rows="10"
                  :rowsPerPageOptions="[10,20,50]" :value="list" class="table" v-model:filters="filters"
                  :globalFilterFields="['lastName','firstName','email','tel','agerLabel','uerLabel','ceiLabel']"
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  removableSort stripedRows sortMode="multiple" responsiveLayout="scroll">
                  <template #empty>
                    {{ $t("list_empty") }}
                  </template>
                  <Column field="lastName" :header="$t('user.last_name')" :sortable="true">
                    <template #body="slotProps">
                          {{ slotProps.data.lastName }}
                    </template>
                  </Column>
                  <Column field="firstName" :header="$t('user.first_name')" :sortable="true">
                    <template #body="slotProps">
                          {{ slotProps.data.firstName }}
                    </template>
                  </Column>
                  <Column field="tel" :header="$t('user.tel')" :sortable="true">
                    <template #body="slotProps">
                          {{ slotProps.data.tel }}
                    </template>
                  </Column>
                  <Column 
                    bodyStyle="text-align: right; overflow: visible"
                    headerStyle="width: 115px; text-align: center">
                    <template #body="slotProps">
                      <span 
                         v-if=this.canManageCeiUserAdmin() 
                         class="btn btn-xs btn-inverse-primary pointer"
                         @click="openUpdateDialog(slotProps.data)">
                        <i class="fe fe-edit"/></span>
                      <span 
                         v-if=this.canManageCeiUserAdmin() 
                         class="btn btn-xs btn-inverse-danger ml-1"
                         @click="openDeleteDialog(slotProps.data)">
                        <i class="fe fe-trash"/></span>
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:extra>
      <AkDialog ref="dialogCreate"
                :title="$t('ceiAgent.create_dialog')"
                :validate-label="$t('add')"
                width="700px"
                @validate="this.create()">
        <div>
          
           <div class="form-row">
             <AkInputText :label="$t('user.last_name')"
                          v-model="current.lastName"
                          :validator="v$.current.lastName"
                          class-name="col-6"/>
             <AkInputText :label="$t('user.first_name')"
                          v-model="current.firstName"
                          :validator="v$.current.firstName"
                          class-name="col-6"/>
              <AkInputText :label="$t('user.tel')"
                          v-model="current.tel"
                          :validator="v$.current.tel"
                          class-name="col-6"/>
           </div>
        </div>
      </AkDialog>
      <AkDialog ref="dialogUpdate"
                :title="$t('ceiAgent.update_dialog')"
                :validate-label="$t('update')"
                width="700px"
                @validate="this.update()">
        <div>
         
           <div class="form-row">
             <AkInputText :label="$t('user.last_name')"
                          v-model="current.lastName"
                          :validator="v$.current.lastName"
                          class-name="col-6"/>
             <AkInputText :label="$t('user.first_name')"
                          v-model="current.firstName"
                          :validator="v$.current.firstName"
                          class-name="col-6"/>
           </div>
           <div class="form-row">

             <AkInputText :label="$t('user.tel')"
                          v-model="current.tel"
                          :validator="v$.current.tel"
                          class-name="col-6"/>
           </div>
          
        </div>
      </AkDialog>
      <AkDialog ref="dialogDelete"
                :auto-hide-on-validate="true"
                :cancel-label="$t('no')"
                :title="$t('ceiAgent.delete_dialog')"
                :validate-label="$t('yes')"
                width="450px"
                @validate="this.delete()">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem" />
          <span >{{ $t('ceiAgent.confirm_delete') }}</span>
        </div>
      </AkDialog>
    </template>
  </AkFormList>
</template>